// Functions first
@import "bootstrap/scss/_functions";

// Variable overrides second
$primary: #7F1D29;
$secondary: #DFC6C9;
$enable-shadows: true;
$prefix: "mo-";

// Required Bootstrap imports
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_variables-dark";
@import "bootstrap/scss/_maps";
@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/_root";

$blockquote-font-size: $font-size-base;

// Optional components
@import "bootstrap/scss/_utilities";
@import "bootstrap/scss/_reboot";
@import "bootstrap/scss/_card";
@import "bootstrap/scss/_containers";
@import "bootstrap/scss/_grid";
@import "bootstrap/scss/_helpers";
@import "bootstrap/scss/utilities/_api";
@import "bootstrap/scss/_alert";
@import "bootstrap/scss/_dropdown";
@import "bootstrap/scss/_images";
@import "bootstrap/scss/_list-group";
@import "bootstrap/scss/_nav";
@import "bootstrap/scss/_navbar";
@import "bootstrap/scss/_tables";
@import "bootstrap/scss/_transitions";
@import "bootstrap/scss/_type";

@import "/src/node_modules/bootstrap-icons/font/bootstrap-icons.scss";
