
.logo-box-text {
    line-height: 1.1em;
    font-size: 13pt;
    margin-left: 13pt;
    font-weight: lighter;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-rot {
    color: #d3242c;
    font-weight: bold;
}
